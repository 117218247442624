<template>
  <div class="q-pa-md ">
    <h1 class="c8">
      <span class="c7">CNFT Privacy Policy</span>
    </h1>
    <h1 class="c9 c13" id="h.ijivqcdcywvd">
      <span class="c6">INFORMATION WE COLLECT</span>
    </h1>
    <p class="c0">
      <span class="c4"
        >We may collect different types of information during your interactions
        with our Sites and through our advertising and media across the Internet
        and mobile apps. This information may include personal information
        (e.g., name, phone number, postal address, email address, and certain
        payment information), technical information (e.g., device identifier, IP
        address, browser type, operating system) and usage information (e.g.,
        how you use and navigate to and from our Sites, and information about
        content or advertisements you have been shown or have clicked on). We
        may combine these types of information together, and collectively refer
        to all of this information in this Privacy Policy as
        &ldquo;Information.&rdquo; Information may be collected as described
        below and through the use of cookies, web beacons, pixels, and other
        similar technologies by us or by other companies on our behalf. Below we
        describe the types of Information we may collect:</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Registration, account, and sign-up information. We may collect
        Information in the course of your use of, or registration with, our
        Sites. For example, when you create an account, register for or download
        an app, or sign-up for a product or service, you may provide us with
        certain personal information. This type of personal information may
        include name, phone number, postal address, fax number, email address,
        or certain payment information (e.g., credit card and billing
        information). We may also collect Information about your interest in and
        use of various products, programs, services, and content available on or
        through our Sites.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Other information you provide. When you interact with our Sites, you
        may share other information about yourself by, for example, publishing
        and sharing the information with the Site&rsquo;s website or in the
        Site&rsquo;s community, creating a or profile, or filing out a survey or
        application. Sometimes you may also choose to provide more sensitive
        forms of personal information to us, such as information regarding your
        physical or mental health, biometric data, race or ethnicity, religious
        or philosophical beliefs, sex life, sexual orientation, political
        opinions, or trade union membership. For example, we may collect this
        type of sensitive information if you participate in surveys, focus
        groups, or opportunities to test new products, programs, or services. We
        collect this sensitive information with your consent, if required by
        law, and we take steps to protect and limit any use of it to the
        purposes for which it is provided.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Information from others; inviting friends. On some of our Sites we may
        collect Information other people submit about you. For example, a friend
        might submit Information to invite you to participate in an offering,
        make recommendations, or share content. By processing these requests, we
        may receive your Information, including a recipient&rsquo;s name, postal
        address, email address, telephone number, or information about your
        interest in and use of various products, programs, services, and
        content. Some of our Sites also allow users to invite friends to
        participate in activities by providing their friends&rsquo; contact
        details or importing contacts from your address book or from other
        sites.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Information from other sources. We may combine Information we receive
        online with other information, including usage information from our
        other Sites and our online advertising and media. We may also supplement
        or combine Information with information from a variety of other sources
        or outside records, such as demographic, transaction history, or
        personal information, and we may use that combined information in
        accordance with this Privacy Policy.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Information you provide through social media. You can engage with some
        of our content and offerings, such as videos, games, apps, and other
        offerings on or through third party communities, forums, and social
        media sites, platforms, services, plug-ins, and applications
        (&ldquo;Social Media Sites&rdquo;). When you link to or interact with
        our Sites, content, or offerings through Social Media Sites, you may
        allow us to receive certain Information from your social media account
        (e.g., name, user ID, email address, profile photo, photos and videos,
        gender, birthday, location, your list of friends and their contact
        details, people you follow and/or who follow you, the posts or the
        &#39;likes&#39; you make). We may also receive Information from your
        interaction with our content (e.g., content viewed, game performance,
        high scores, and information about advertisements you have been shown or
        have clicked on). By providing this Information or otherwise interacting
        with our Sites through Social Media Sites, you consent to our use of
        Information from the Social Media Sites in accordance with this Privacy
        Policy. For information about how you can customize your privacy
        settings on Social Media Sites, and how those Social Media Sites handle
        your personal information and content, please refer to their privacy
        help guides, privacy policies, and terms of use.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Information you provide through public forums. If you post or share
        Information or content, such as photos, letters, videos, or comments,
        while participating in online forums on our Sites, or when you interact
        with our Sites through Social Media Sites, depending on your privacy
        settings, this Information or content and your username may become
        public on the Internet or within a community of users. We cannot prevent
        further use of this Information once you share it in a publicly
        available forum. For information about how you can customize your
        privacy settings on Social Media Sites, and how those Social Media Sites
        handle your personal information and content, please refer to their
        privacy help guides, privacy policies, and terms of use.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Location information. We may have access to certain Information about
        your location, such as your country or address, when you provide it
        either directly or via device information. If you access our Sites on
        your mobile device, we may collect Information about your device&rsquo;s
        precise location. We also may derive a general location from device
        information (such as an IP address).</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Information you provide through camera access. For some Sites, we will
        ask for permission to access your device&rsquo;s camera. If you grant
        permission, you may be able to take pictures or video within the app
        experience or to access certain augmented reality (&ldquo;AR&rdquo;)
        features. Some of these features may rely on camera systems to track
        movements of your eyes and other facial features or your immediate
        surroundings to apply AR effects. Information gathered from some facial
        scanning technology (e.g. TrueDepth API), is only used to make these
        services and features available to you and is only persistent on the
        device during use of the AR features. Other facial scanning technology
        may be used for research, analytics, and enhancing consumer
        experiences.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Video and streaming information. For some Sites, we collect information
        about the films, TV shows, and videos that you view. For example, if you
        stream video content using our Sites, we may collect information about
        your interaction with that content or service, such as the title and
        genre, watchlists you compile, and searches you conduct, duration and
        number of streams and downloads and system information related to
        streaming and download quality.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Technical and usage information. We also collect certain technical and
        usage information when you use our Sites, such as the type of device,
        browser, and operating system you are using, your Internet service
        provider or mobile carrier, unique device identifier, IDFA or IDFV, MAC
        address, IP address, device and browser settings, the webpages and apps
        you use, advertisements you see and interact with, and certain Site
        usage information. See our Cookies and Other Technical Information
        section for more information on how we may use these technologies to
        collect this Information.</span
      >
    </p>
    <h2 class="c3" id="h.zgu0281szsno">
      <span class="c6">HOW WE USE THE INFORMATION</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >We use Information for the purposes described in this policy or
        disclosed at the time of collection or with your consent.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Providing and marketing products and services. We may use the
        Information we collect about you through our Sites to fulfill your
        requests for, and otherwise provide or analyze your use of our products,
        programs, services and content, to facilitate sharing and other
        interactions with Social Media Sites, and to provide, develop, maintain,
        personalize, protect, and improve your experience and our offerings. For
        example, we use Information we collect to enable you to do things like
        (i) watch trailers, movies, programs, and video clips, (ii) get
        entertainment news and updates, (iii) get information about our
        products, programs, services, and content, (iv) locate and access
        personalized information or functionality based on your interests or
        location (e.g., find stores, theaters, or show times), (v) buy digital
        content, movie tickets, or other purchases (vi) play games, (vii) engage
        with interactive features, activities, and Social Media Sites, (viii)
        read and post comments, content, and reviews, (ix) fill out surveys or
        provide feedback, or (x) enter promotions, contests, and sweepstakes. We
        may also use Information to offer, market, and advertise products,
        programs, and services from us and our affiliates, business partners,
        and select third parties that we believe may be of interest to
        you.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Communicating with you and others. We may use Information about you to
        communicate with you, such as (i) to notify you when you win one of our
        contests or sweepstakes or when we make changes to our policies or user
        agreements, (ii) to respond to your inquiries and provide you with
        customer service, (iii) to communicate with you about your purchases or
        transactions, (iv) to contact you about your account, or (v) to send you
        information about promotions, offerings, and Site features. You may also
        choose to receive push notifications from us on your mobile device. If
        you choose to submit content for publication online or in other forums,
        we may publish your screen name or username and other Information you
        have provided to us on our Sites, the Internet, or elsewhere. We use
        Information that you provide about others to enable us to send them
        invitations, promotions, or other content on your behalf or through our
        Sites. From time to time, we also may use this Information to offer,
        market, or advertise products, programs, or services to them from us and
        our affiliates, and business partners.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Use of technical and usage information. We may use technical and usage
        information to improve the design, functionality and content of our
        Sites and to enable us to personalize your experience with our Sites and
        offerings. For example, we may &nbsp;use this Information (i) to
        provide, develop, maintain, personalize, protect, and improve our Sites,
        products, programs, and services and to operate our business, (ii) to
        perform analytics, including to analyze and report on usage and
        performance of our Sites, (iii) to protect against, identify, and
        prevent fraud and other unlawful activity, (iv) to create aggregate data
        about groups or categories of our users, and (iv) for us and our
        affiliates, business partners, and select third parties to target,
        offer, market, or advertise products, programs, or services.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Compliance. We may use Information we collect to detect, investigate,
        and prevent activities on our Sites that may violate our terms of use,
        could be fraudulent, violate copyright, or other rules or that may be
        otherwise illegal, to comply with legal requirements, and to protect our
        rights and the rights and safety of our users and others.</span
      >
    </p>
    <h2 class="c3" id="h.ywafsrak5e6o">
      <span class="c6">INFORMATION SHARING AND DISCLOSURE</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >We may share and disclose Information in the following ways or for any
        other purpose disclosed at the time of collection:</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >With your consent. We may disclose Information when you provide us with
        your consent to do so.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Legal and law enforcement purposes. We may disclose Information in
        response to a legal process, for example in response to a court order or
        a subpoena, or in response to a law enforcement agency&#39;s request. We
        also may disclose such Information to third parties: (i) in connection
        with fraud prevention activities, (ii) where we believe it is necessary
        to investigate, prevent, or take action regarding illegal activities,
        (iii) in situations that may involve violations of our terms of use or
        other rules, (iv) to protect our rights and the rights and safety of
        others, and (v) as otherwise required by law.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Change of control. We may transfer Information in the event of a
        business transaction, such as if we or one of our business units or our
        relevant assets are acquired by, sold to, or merged with another company
        or as part of a bankruptcy proceeding or a business
        reorganization.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Service providers. Organizations that perform services for us may have
        access to Information to help carry out the services they are performing
        for us, such as, but not limited to, creation, maintenance, hosting, and
        delivery of our Sites, products, and services, conduct marketing, handle
        payments, email and order fulfillment, administer contests, conduct
        research and analytics, or customer service.</span
      >
    </p>
    <p class="c0">
      <span class="c9"
        >Affiliates. We may disclose Information to affiliates. For example, we
        may share Information we collect with our </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.warnermediaprivacy.com/policycenter/b2c/affiliateslist/&amp;sa=D&amp;source=editors&amp;ust=1631149941706000&amp;usg=AOvVaw0QNCHtrlEChg8CwSOEogMT"
          >affiliates</a
        ></span
      ><span class="c9"
        >&nbsp;to provide, improve, offer, market, and otherwise communicate
        with you about their own products and services. Our products and
        services may be developed, managed, marketed and sold by a variety of
        our affiliates. We share Information that may identify you internally
        among affiliates publicly recognized as a WarnerMedia affiliate, such as
        Xandr and the AT&amp;T family of companies. Public recognition may
        derive from, for example, use of common branding elements, advertising,
        press, social media reports or other public form of notice. We require
        the affiliate to protect the Information consistent with this Policy. We
        may also combine Information that identifies you personally with data
        that comes from an affiliate that has a different privacy policy. When
        we do that, our Policy applies to the combined data set. You can learn
        about our WarnerMedia and AT&amp;T affiliates by visiting the
      </span>
      <span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.warnermediaprivacy.com/policycenter/b2c/affiliateslist/&amp;sa=D&amp;source=editors&amp;ust=1631149941706000&amp;usg=AOvVaw0QNCHtrlEChg8CwSOEogMT"
          >WarnerMedia affiliates&rsquo; page</a
        ></span
      ><span class="c4">. </span>
    </p>
    <p class="c0">
      <span class="c4"
        >Business partners and third parties. We may also share Information with
        business partners and third parties (e.g., other companies, retailers,
        research organizations, advertisers, ad agencies, advertising networks
        and platforms, participatory databases, publishers, and non-profit
        organizations) that may want to market products or services to you. If
        we share personal information with such unaffiliated third parties for
        their own marketing purposes, we provide you with an opportunity to opt
        out of such uses either at the point of collection or through the choice
        mechanisms set forth in this Privacy Policy. To learn more about your
        choices, please see our Your Choices and Controls section below.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Linked sites. Some of our Sites contain links to other sites, including
        Social Media Sites, whose information practices may be different from
        ours. Information you submit to other sites will be governed by the
        other sites&#39; privacy policies and terms.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Sponsors and co-promotions. We may sometimes offer content or programs
        (e.g., contests, sweepstakes, promotions, games, applications or Social
        Media Site integrations) that are sponsored by or co-branded with
        identified third parties. By virtue of these relationships, the
        sponsoring or co-branding parties may collect or obtain Information from
        visitors that participate in the activity. We have no control over these
        sponsoring or co-branding parties&#39; use of this Information. We
        encourage you to visit the privacy policy of any such sponsoring or
        co-branding party to learn about their data practices prior to providing
        Information through sponsored or co-branded content or programs.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Advertising networks. We may share certain information with parties to
        provide advertising to you based on your interests. For more
        information, please see our Ad Choices section below.</span
      >
    </p>
    <h2 class="c3" id="h.4golb9e78bh">
      <span class="c6">YOUR CHOICES AND CONTROLS</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >Marketing communications and sharing with third parties. We provide you
        with an opportunity to express your preferences with respect to
        receiving certain marketing communications from us, and our sharing of
        personal information with unaffiliated third parties for their direct
        marketing purposes. If you ever decide in the future that you would like
        to update these preferences, you may (i) log in to an account you have
        created with us at one of our Sites to adjust your settings, or (ii)
        send us an email at wmprivacy@warnermedia.com. To opt out of receiving
        our email marketing communications, you can also follow the
        &quot;unsubscribe&quot; instructions provided in any marketing email you
        receive from us. If you previously chose to receive push notifications
        on your mobile device from us, but no longer wish to receive them, you
        can manage your preferences through your device or app settings,
        depending on the type of device. If you have signed up to receive text
        messages from us and no longer wish to receive such messages, you may
        follow the instructions to stop the delivery of such messages, which may
        include by replying &quot;STOP&quot; to the received text message. For
        California residents, please see below for additional information on the
        choices we provide to you.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Ad Choices. On our own or working with affiliates or third parties, we
        may present advertisements and engage in data collection, reporting, ad
        delivery and response measurement, and site analytics on our Sites and
        on third party websites across the Internet and applications over time.
        We, our affiliates, or third parties may use cookies, web beacons,
        pixels, software development kits (&ldquo;SDKs&rdquo;) or similar
        technologies to collect information across websites, services, and apps
        over time to perform this activity. We, our affiliates, or third parties
        may use and transfer this information in order to help serve advertising
        that may be more relevant to your interests on and off our Sites and
        across your devices and browsers. This type of advertising is known as
        interest-based advertising. We, our affiliates, or third parties may
        also use this information to associate your different browsers and
        devices together for interest-based advertising and for other purposes
        like research, analytics, internal operations, fraud prevention, and
        enhancing consumer experiences.</span
      >
    </p>
    <p class="c0">
      <span class="c9"
        >For more information about interest-based advertising on your desktop
        or mobile browser, and to opt out of this type of advertising by third
        parties that participate in self-regulatory programs, please visit the </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options&amp;sa=D&amp;source=editors&amp;ust=1631149941708000&amp;usg=AOvVaw1QBnjOpH_Vi2aQ3bROC-0J"
          >Network Advertising Initiative website</a
        ></span
      ><span class="c9">&nbsp;and/or the </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=http://optout.aboutads.info/choices?c%3D2%26lang%3DEN&amp;sa=D&amp;source=editors&amp;ust=1631149941708000&amp;usg=AOvVaw0pI8LNYijQWf5QQ6vJf0aU"
          >Digital Advertising Alliance (&ldquo;DAA&rdquo;) Self-Regulatory
          Program for Online Behavioral Advertising website</a
        ></span
      ><span class="c9"
        >. To learn more about interest-based advertising in mobile apps and to
        opt out of this type of advertising by third parties that participate in
        the DAA&rsquo;s AppChoices tool, please use this link to download the </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://youradchoices.com/appchoices&amp;sa=D&amp;source=editors&amp;ust=1631149941708000&amp;usg=AOvVaw13PAFUotV0mMsj1a9LX4iQ"
          >version of AppChoices for your device</a
        ></span
      ><span class="c4"
        >. Please note that any opt-out choice you exercise through these
        programs will apply to interest-based advertising by the third parties
        you select, but will still allow the collection of data for other
        purposes, including research, analytics, and internal operations. You
        may continue to receive advertising, but that advertising may be less
        relevant to your interests.</span
      >
    </p>
    <p class="c0">
      <span class="c9"
        >To opt out of our own collection, use, and transfer of data as
        described above for interest-based advertising, please visit our </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.warnermediaprivacy.com/opt-out/&amp;sa=D&amp;source=editors&amp;ust=1631149941709000&amp;usg=AOvVaw1RNJRTQJ1iduiXc2RByCIn"
          >Opt-Out page
        </a></span
      ><span class="c4"
        >to opt-out. Please note that if you opt out, we will continue to
        collect data for other purposes, including research, analytics, and
        internal operations. You may continue to receive advertising on our
        Sites based on your activities on our Sites.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >You may have more options depending on your mobile device and operating
        system. For example, most device operating systems (e.g., iOS for Apple
        phones, Android for Android devices, and Windows for Microsoft devices)
        provide their own instructions on how to limit or prevent the delivery
        of tailored in-application advertisements. You may review the support
        materials and/or the privacy settings for the respective operating
        systems to learn more about these features and how they apply to
        tailored in-app advertisements.
      </span>
    </p>
    <p class="c0">
      <span class="c4"
        >Precise location information. To disable the collection of precise
        location information from your mobile device through our mobile apps,
        you can access your mobile device settings and choose to limit that
        collection.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >See also our Cookies and Other Technical Information section for more
        choices about managing other technical and usage information.</span
      >
    </p>
    <h2 class="c3" id="h.7uwqcof890q0">
      <span class="c6">COOKIES AND OTHER TECHNICAL INFORMATION</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >Cookies and other technologies. We, and our affiliates, vendors, and
        business partners may send &ldquo;cookies&rdquo; to your computer or use
        similar technologies to understand and enhance your online experience at
        our Sites and through our advertising and media across the Internet and
        mobile apps.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Cookies are small text files placed in your browser. We may also use
        pixels or &ldquo;web beacons&rdquo; that monitor your use of our Sites.
        Web beacons are small strings of code that provide a method for
        delivering a graphic image on a webpage for the purpose of transferring
        data, such as the IP address of the computer that downloaded the page on
        which the web beacon appears, the URL of the page on which the web
        beacon appears, the time the page containing the web beacon was viewed,
        the type of browser that fetched the web beacon, and the identification
        number of any cookie on the computer previously placed by that server.
        We may also integrate SDKs into our applications to perform similar
        functions as cookies and web beacons. For example, SDKs may collect
        technical and usage information such as mobile device identifiers and
        your interactions with the Site and other mobile apps.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >We may use cookies and other technologies to help recognize your
        browser or device, maintain your preferences, provide certain Site
        features, and collect Information about interactions with our Sites, our
        content, and our communications. For example, when corresponding with
        you via HTML capable email, web beacons and other technologies let us
        know about your activity, including whether you received and opened our
        email, clicked through a link, or otherwise interacted with our content,
        and this information may be associated with Information previously
        collected.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >We may also use cookies and other technologies (i) to provide, develop,
        maintain, personalize, protect, and improve our Sites, products,
        programs, and services and to operate our business, (ii) to perform
        analytics, including to analyze and report on usage and performance of
        our Sites and marketing materials, (iii) to protect against, identify,
        and prevent fraud and other unlawful activity, (iv) to create aggregate
        data about groups or categories of our users, (v) to synchronize users
        across devices, affiliates, business partners, and select third parties,
        and (vi) for us and our affiliates, business partners, and select third
        parties to target, offer, market, or advertise products, programs, or
        services. Cookies and other technologies also facilitate, manage, and
        measure the performance of advertisements displayed on or delivered by
        or through us and/or other networks or sites. By visiting the Site,
        whether as a registered user or otherwise, you acknowledge, and agree
        that you are giving us your consent to track your activities and your
        use of the Site through the technologies described above, as well as
        similar technologies developed in the future, and that we may use such
        tracking technologies in the emails we send to you.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Managing cookies and other technologies. Cookies can either be
        persistent (i.e., they remain on your computer until you delete them) or
        temporary (i.e., they last only until you close your browser). Check
        your browser settings to learn how to delete cookies.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >You may adjust your browser to reject cookies from us or from any other
        website. Controlling cookies via browser controls may not limit our use
        of other technologies. Please consult your browser&rsquo;s settings for
        more information. However, blocking cookies or similar technology might
        prevent you from accessing some of our content or Site features.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >Some of our Sites may use locally stored objects (&ldquo;LSOs&rdquo;)
        to provide certain content, such as video on demand, video clips, or
        animation, and a better user experience. Adobe&#39;s Flash player and
        similar applications use this technology to remember settings,
        preferences, and usage similar to browser cookies. Flash cookies are not
        managed through your web browser, but you can access your Flash
        management tools by visiting Adobe&#39;s web site. Your browser may also
        offer other tools to delete or reject other LSOs; please check your
        browser&rsquo;s settings or help menu for more information.</span
      >
    </p>
    <p class="c0">
      <span class="c9"
        >Some of our sites may use Google Analytics to analyze traffic. You can
        find out more information about Google Analytics cookies by visiting the
        following location provided: </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage&amp;sa=D&amp;source=editors&amp;ust=1631149941711000&amp;usg=AOvVaw0AckjEVwBggDNPvbYsfTdt"
          >https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a
        ></span
      ><span class="c9"
        >. To opt out of Google Analytics relating to your use of our Sites, you
        can download and install the Browser Plugin available by visiting the
        following location provided: </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1631149941711000&amp;usg=AOvVaw1nhAThTqq2evwcpB8AWG6q"
          >https://tools.google.com/dlpage/gaoptout?hl=en</a
        ></span
      ><span class="c4">.</span>
    </p>
    <p class="c0">
      <span class="c9"
        >Some Sites may feature Nielsen proprietary measurement software, which
        will allow users to contribute to market research, such as Nielsen TV
        Ratings. To learn more about the information that Nielsen software may
        collect and your choices, please see the Nielsen Digital Measurement
        Privacy Policy by visiting the following location provided: </span
      ><span class="c5"
        ><a
          class="c1"
          href="https://www.google.com/url?q=https://www.nielsen.com/us/en/legal/privacy-statement/digital-measurement/&amp;sa=D&amp;source=editors&amp;ust=1631149941712000&amp;usg=AOvVaw0YWA1Ndjr51aHbW4loTm0M"
          >https://www.nielsen.com/us/en/legal/privacy-statement/digital-measurement/</a
        ></span
      ><span class="c4">.</span>
    </p>
    <p class="c0">
      <span class="c4"
        >We do not currently take actions to respond to Do Not Track signals
        because a uniform technological standard has not yet been developed. We
        continue to review new technologies and may adopt a standard once one is
        created.</span
      >
    </p>
    <p class="c0">
      <span class="c4"
        >See the Your Choices and Controls section to learn how to control data
        collection for certain purposes.</span
      >
    </p>
    <h2 class="c3" id="h.5kjq4vryvdig">
      <span class="c6">INFORMATION SECURITY</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >We have put in place reasonable controls designed to help safeguard the
        personal information we collect via the Sites. However, no security
        measures are perfect, and we cannot assure you that personal information
        that we collect will never be accessed or used in an unauthorized
        way.</span
      >
    </p>
    <h2 class="c3" id="h.uvxgel573zq2">
      <span class="c9 c11">HOW YOU CAN ACCESS OR CORRECT INFORMATION</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >To access personal information that we have collected about you online
        from Sites on which this Privacy Policy is posted, or to update your
        user profile, please log into your account if you have created one with
        us, or send an email to wmprivacy@warnermedia.com. For California
        residents, please see below for additional information on accessing
        information about you.</span
      >
    </p>
    <h2 class="c3" id="h.r4ecsqmcnpbi">
      <span class="c6"
        >ADDITIONAL INFORMATION REGARDING CHILDREN&#39;S PRIVACY</span
      >
    </h2>
    <p class="c0">
      <span class="c9"
        >On most Sites, we do not knowingly collect information from children.
        On some Sites, we may ask the user to provide us with the user&#39;s age
        information. If the person indicates that he or she is under 13 years
        old, as permitted by law, we will (i) collect no or limited personal
        information (e.g. persistent identifier and/or email address only) from
        that individual, (ii) inform the child that a parent&#39;s verifiable
        consent is required, and/or (iii) collect the email address of the
        user&#39;s parent in addition to the user&#39;s email address. We may
        use the parent&#39;s email address to seek the parent&#39;s verifiable
        consent or notify the parent of his/her child&#39;s online activities
        and enable the parent to unsubscribe his/her child from a newsletter or
        other similar activity. Once a parent provides consent, we may use any
        information collected from a child consistent with the rest of this
        Privacy Policy and/or the terms of the consent provided by the parent.
        If a user is under 13, we will not condition his/her participation in an
        online activity on the disclosure of more personal information than is
        reasonably necessary to participate in the activity. If you would like
        to review any personal information that we have collected online from
        your child, have this personal information deleted from our active
        servers, and/or request that there be no further collection or use of
        your child&#39;s personal information, or if you have questions about
        these information practices, you may contact us at </span
      ><span class="c5 c12"
        ><a class="c1" href="mailto:cnft.co@gmail.com"
          >cnft.co@gmail.com</a
        ></span
      ><span class="c4">.</span>
    </p>
    <h2 class="c3" id="h.kvf05i70f4q8">
      <span class="c6">INTERNATIONAL TRANSFER</span>
    </h2>
    <p class="c0">
      <span class="c4"
        >We operate internationally, and many of our computer systems are
        currently based in the United States, which means Information we collect
        will be processed by us in the U.S. where data protection and privacy
        regulations may not offer the same level of protection as in other parts
        of the world, such as the European Union. If you use or visit our Sites
        from outside the United States, you consent to the collection and/or
        processing in the United States of Information we collect from
        you.</span
      >
    </p>
    <h2 class="c3" id="h.11j1e92z7s7f">
      <span class="c6"
        >CALIFORNIA AND CCPA PRIVACY RIGHTS, METRICS, AND DISCLOSURES</span
      >
    </h2>
    <p class="c0">
      <span class="c4"
        >This California and CCPA Privacy Rights, Metrics, and Disclosure
        section addresses legal obligations and rights laid out in the
        California Consumer Privacy Act (&ldquo;CCPA&rdquo;) and other laws that
        apply only to California residents. These obligations and rights apply
        to businesses doing business in California and to California residents
        and information that identifies, relates to, describes, is reasonably
        capable of being associated with, or could reasonably be linked,
        directly or indirectly, with California consumers or households
        (&ldquo;California Information&rdquo;). It does not apply to information
        that has been de-identified or aggregated as provided by CCPA.
      </span>
    </p>
    <p class="c0 c15"><span class="c4"></span></p>
    <p class="c0"><span class="c6">HOW TO CONTACT US</span></p>
    <p class="c0">
      <span class="c9"
        >If you have any questions about this Privacy Policy you may contact us
        at: </span
      ><span class="c12 c5"
        ><a class="c1" href="mailto:cnft.co@gmail.com"
          >cnft.co@gmail.com</a
        ></span
      ><span class="c4">&nbsp;</span>
    </p>
    <p class="c10"><span class="c14"></span></p>
  </div>
</template>
<style lang="sass" scoped>
.faq
  max-width: 80%
h1
  font-size: 2rem
h2
  font-size: 1.5rem
</style>

<script>
export default {
  name: "Privacy",
};
</script>
